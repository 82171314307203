<template>
  <Messenger />
  <Loading />
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "./assets/css/main.css";
import Messenger from "./components/utils/Messenger.vue";
import Loading from "./components/utils/Loading";
import store from "./store";

export default {
  name: "App",
  components: {
    Messenger,
    Loading,
  },
  created() {
    // Check if token exists in local storage
    console.log("Checking token...");
    const token = localStorage.getItem("token");
    if (token) {
      this.$backendDialog
        .checkToken()
        .then((response) => {
          console.log(response);
          localStorage.setItem("token", token);
          store.commit("setToken", token);
          store.commit("sendMessage", {
            type: "success",
            text: "Welcome back!",
          });
          this.$router.push({ path: "home/prompt" });
        })
        .catch(() => {
          console.log("Token not found");
          localStorage.removeItem("token");
          this.$router.push({ path: "/" });
        });
    } else {
      console.log("No token");
    }
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  $color1: #d7e2e3; // Stroke
  $color2: #e5e5f7; // Fill
  height: 100vh;
  background-image: linear-gradient(
      $color1 1px,
      $color2 1px,
      $color2 calc(100% - 1px),
      $color1 calc(100% - 1px)
    ),
    linear-gradient(
      90deg,
      $color1 1px,
      $color2 1px,
      $color2 calc(100% - 2px),
      $color1 calc(100% - 1px)
    );
  background-size: 15px 15px;
  overflow: hidden;
}
</style>
