import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        path: "prompt",
        component: () => import("../views/home/PromptView.vue"),
      },
      {
        path: "history",
        component: () => import("../views/home/requests/HistoryView.vue"),
      },
      {
        path: "completeResults/:requestId",
        component: () =>
          import("../views/home/requests/CompleteResultsView.vue"),
      },
      {
        path: "lessons",
        component: () => import("../views/home/LessonsView.vue"),
      },
    ],
  },
  // Redirect 404 to login
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
