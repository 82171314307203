import { createStore } from "vuex";
import services from "@/services/services";

const messageTimeout = 10000;

class Request {
  constructor(name, code, progress, cancelCallback) {
    this.name = name;
    this.code = code;
    this.progress = progress;
    this.cancelCallback = cancelCallback;
    if (this.cancelCallback) this.cancellable = true;
    // Number of elements processed at the moment
    this.quantity = 0;

    // Get creation time
    this.creationTime = Date.now();
  }

  cancel() {
    if (this.cancellable) {
      this.cancelCallback();
    }
  }
}

export default createStore({
  state: {
    token: null, // Token for the backend
    messages: [], // List of messages for the user
    requests: [], // Request pending to the backend
    promptBeingProcessed: false, // True if a prompt is being processed
  },
  getters: {},
  mutations: {
    // login
    setToken(state, token) {
      state.token = token;
    },
    // Messages
    sendMessage(state, msg) {
      // { type: "error", text: "Error message" }
      msg.id = services.uuid();
      state.messages.push(msg);
      setTimeout(() => {
        state.messages = state.messages.filter((m) => m.id != msg.id);
      }, messageTimeout);
    },
    removeMessage(state, msg) {
      state.messages = state.messages.filter((m) => m != msg);
    },

    // Requests
    startRequest(state, { name, code, progress, cancelCallback }) {
      let newRequest = new Request(name, code, progress, cancelCallback);
      state.requests.push(newRequest);
    },
    // startRequest(state, test) {
    //   // let newRequest = new Request(name, code, progress, cancelCallback);
    //   // state.requests.push(newRequest);
    // },
    endRequest(state, code) {
      state.requests = state.requests.filter((r) => r.code !== code);
    },
    updateRequestProgress(state, { code, progress }) {
      const request = state.requests.find((r) => r.code == code);
      if (!request) return;
      request.progress = progress;
    },
    updateRequestQuantity(state, { code, quantity }) {
      const request = state.requests.find((r) => r.code == code);
      if (!request) return;
      request.quantity = quantity;
    },
    cancelRequest(state, code) {
      const request = state.requests.find((r) => r.code == code);
      if (!request) return;
      request.cancel();
      // Remove request
      state.requests = state.requests.filter((r) => r.code !== code);
    },
    setPromptBeingProcessed(state, value) {
      state.promptBeingProcessed = value;
    },
  },
  actions: {},
  modules: {},
});
