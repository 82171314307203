<template>
  <div
    title="Match percentage"
    v-if="distance && percentage"
    id="DistanceDisplayer"
    :class="getClassName(percentage)"
  >
    {{ percentage }} {{ "%" }}
  </div>
</template>

<script>
export default {
  name: "DistanceDisplayer",
  props: {
    distance: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    percentage() {
      return Math.floor((1 - this.distance) * 100);
    },
    getClassName() {
      return (percentage) => {
        if (percentage >= 75) return "veryGood";
        else if (percentage >= 70) return "good";
        else if (percentage >= 65) return "medium";
        else if (percentage >= 60) return "bad";
        else return "veryBad";
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#DistanceDisplayer {
  white-space: nowrap;
  font-weight: bold;
  color: var(--primary);
  margin-left: 10px;

  &.veryGood {
    color: var(--veryGood);
  }
  &.good {
    color: var(--good);
  }
  &.medium {
    color: var(--medium);
  }
  &.bad {
    color: var(--bad);
  }
  &.veryBad {
    color: var(--veryBad);
  }
}
</style>
