import axios from "axios";
import store from "../store";
import services from "./services";

function startRequest(name) {
  let requestCode = services.uuid();
  store.commit("startRequest", { name, code: requestCode });

  return requestCode;
}

function endRequest(code) {
  store.commit("endRequest", code);
}

function getToken() {
  return store.state.token;
}

export default {
  // Login
  login(username, password) {
    let code = startRequest("Logging in");
    return axios
      .post("/login", { username, password })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },
  checkToken(token) {
    let code = startRequest("Checking token");
    console.log("Checking token");
    console.log(token);
    return axios
      .post("/checkToken", { token })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },

  // Lessons
  getLessons(showMessage = true) {
    let code;
    if (showMessage) code = startRequest("Getting lessons");
    return axios
      .get("/lessons", { headers: { token: getToken() } })
      .finally(() => {
        if (showMessage) endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },
  getLesson(lessonName) {
    return axios
      .get("/lessons/" + lessonName + "/", { headers: { token: getToken() } })
      .then((response) => {
        return response.data;
      });
  },
  async addLesson(lesson) {
    console.log("Adding lesson: " + lesson.name);

    let formData = new FormData();
    formData.append("file", lesson.file);
    formData.append("filename", lesson.name);

    // File must be in body
    return axios
      .post("/lessons", formData, {
        headers: {
          token: getToken(),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.loaded / progressEvent.total;
          lesson.uploadProgress = percentCompleted;
        },
      })
      .finally(() => {
        lesson.uploadProgress = 1;
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteLesson(lessonName) {
    let code = startRequest("Deleting lesson");
    return axios
      .delete("/lessons/" + lessonName + "/", {
        headers: { token: getToken() },
      })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },

  // Requests
  getRequests() {
    let code = startRequest("Getting requests");
    return axios
      .get("/requests", { headers: { token: getToken() } })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },
  getRequest(requestId) {
    let code = startRequest("Getting request");
    return axios
      .get("/requests/" + requestId + "/", { headers: { token: getToken() } })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },
  getRequestFullResults(requestId) {
    let code = startRequest("Getting request full results");
    return axios
      .get("/requests/" + requestId + "/completeResults", {
        headers: { token: getToken() },
      })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },
  addRequest(prompt) {
    let code = startRequest("Requesting");
    return axios
      .post("/requests", { prompt }, { headers: { token: getToken() } })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteRequest(requestId) {
    let code = startRequest("Deleting request");
    return axios
      .delete("/requests/" + requestId + "/", {
        headers: { token: getToken() },
      })
      .finally(() => {
        endRequest(code);
      })
      .then((response) => {
        return response.data;
      });
  },

  // Quick search
  quickSearch(keyword) {
    return axios
      .post("/quickSearch", { keyword }, { headers: { token: getToken() } })
      .then((response) => {
        return response.data;
      });
  },
};
