// Custom
import Modal from "./Modal.vue";
import DocumentationBlock from "./DocumentationBlock .vue";
import AvailableTag from "./AvailableTag.vue";
import DistanceDisplayer from "./DistanceDisplayer.vue";
import LoadingAnimation from "./LoadingAnimation.vue";

export default [
  Modal,
  DocumentationBlock,
  AvailableTag,
  DistanceDisplayer,
  LoadingAnimation,
];
